import { SplinePoint } from 'src/utils/spline';

export function uv(p1: { x: number; y: number }, p2: { x: number; y: number }) {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  const l = Math.sqrt(dx * dx + dy * dy);

  return { x: dx / l, y: dy / l };
}

export function vw(v: number) {
  return `${(v / 1300) * 100}vw`;
}

export function splinePoint(x: number, y: number): SplinePoint {
  return {
    x,
    y,
    originX: x,
    originY: y,
    noiseOffsetX: Math.random() * 1000,
    noiseOffsetY: Math.random() * 1000,
  };
}

export function randomUV() {
  const rad = Math.random() * Math.PI * 2;
  return { x: Math.cos(rad), y: Math.sin(rad) };
}
