/**
 * https://github.com/georgedoescode/splinejs
 */
function formatPoints(points: SplinePoint[], close: boolean) {
  const result = (Array.isArray(points[0]) ? [...points] : points.map(({ x, y }) => [x, y])) as number[][];

  if (close) {
    const lastPoint = result[result.length - 1];
    const secondToLastPoint = result[result.length - 2];

    const firstPoint = result[0];
    const secondPoint = result[1];

    result.unshift(lastPoint);
    result.unshift(secondToLastPoint);

    result.push(firstPoint);
    result.push(secondPoint);
  }

  return result.flat();
}

export type SplinePoint = {
  x: number;
  y: number;
  originX: number;
  originY: number;
  noiseOffsetX: number;
  noiseOffsetY: number;
};

export function spline(points: SplinePoint[] = [], tension = 1, close = false, cb: any = null) {
  const formattedPoints = formatPoints(points, close);

  const size = formattedPoints.length;
  const last = size - 4;

  const startPointX = close ? formattedPoints[2] : formattedPoints[0];
  const startPointY = close ? formattedPoints[3] : formattedPoints[1];

  let path = 'M' + [startPointX, startPointY];

  cb && cb('MOVE', [startPointX, startPointY]);

  const startIteration = close ? 2 : 0;
  const maxIteration = close ? size - 4 : size - 2;
  const inc = 2;

  for (let i = startIteration; i < maxIteration; i += inc) {
    const x0 = i ? formattedPoints[i - 2] : formattedPoints[0];
    const y0 = i ? formattedPoints[i - 1] : formattedPoints[1];

    const x1 = formattedPoints[i + 0];
    const y1 = formattedPoints[i + 1];

    const x2 = formattedPoints[i + 2];
    const y2 = formattedPoints[i + 3];

    const x3 = i !== last ? formattedPoints[i + 4] : x2;
    const y3 = i !== last ? formattedPoints[i + 5] : y2;

    const cp1x = x1 + ((x2 - x0) / 6) * tension;
    const cp1y = y1 + ((y2 - y0) / 6) * tension;

    const cp2x = x2 - ((x3 - x1) / 6) * tension;
    const cp2y = y2 - ((y3 - y1) / 6) * tension;

    path += 'C' + [cp1x, cp1y, cp2x, cp2y, x2, y2];

    cb && cb('CURVE', [cp1x, cp1y, cp2x, cp2y, x2, y2]);
  }

  return path;
}
