import { CAN_USE_DOM, IS_DEV, SHARE_CONSTANTS } from 'src/constants/other';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const hostname = 'https://myname.ynet.co.il/';
if (CAN_USE_DOM) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    hostname: hostname,
  });
}

export const onShareGA = (social: keyof typeof SHARE_CONSTANTS) => {
  const params = {
    event: 'GA_event',
    Category: 'Social',
    Action: 'Header',
    Label: social,
  };

  // return !IS_DEV && window.dataLayer.push(params);
  return window.dataLayer.push(params);
};
