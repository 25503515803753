import React from 'react';
import cn from 'classnames';

import { Accessibility } from 'src/components/common';
import TopSection from './TopSection';
import Section from './Section';
import Footer from './Footer';

import { SectionsData } from './sections-data';

import css from './Main.module.scss';

const Main = () => {
  React.useEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      window.scrollTo(0, 0);
    });
  }, []);

  return (
    <div className={css.mainWrapper}>
      <Accessibility />
      <TopSection />
      <main className={css.mainContent}>
        {SectionsData.map((sectionData, index) => {
          const { description, imageClassName, ...props } = sectionData;

          return (
            <Section
              {...props}
              key={`${props.imgUrl}/${index}`}
              imageClassName={cn(imageClassName && css[imageClassName])}
              className={css.section}
              shortDescriptionMobile={sectionData.shortDescriptionMobile}
              longDescriptionMobile={sectionData.longDescriptionMobile}
              isNeedToOpenLongDescription={sectionData.isNeedToOpenLongDescription}
              index={index}
            >
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </Section>
          );
        })}
      </main>
      <Footer />
    </div>
  );
};

export default Main;
