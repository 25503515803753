import React, { FC } from 'react';
import cn from 'classnames';

import { SHARE_CONSTANTS } from 'src/constants/other';
import { useResponsive } from 'src/hooks/responsive';
import { onShareGA } from 'src/utils/gtag';
import { share } from 'src/utils/share';

import facebook from 'src/assets/socials/facebook.svg';
import twitter from 'src/assets/socials/twitter.svg';
import sms from 'src/assets/socials/sms.svg';
import whatsup from 'src/assets/socials/whatsup.png';

import css from './Socials.module.scss';

type Props = {
  className?: string;
};
const Socials: FC<Props> = (props) => {
  const { className = '' } = props;
  const [isDesktop] = useResponsive('DESKTOP');

  const onShare = (e: React.SyntheticEvent<HTMLElement>) => {
    const type = e.currentTarget.dataset.id;

    if (type) onShareGA(type as keyof typeof SHARE_CONSTANTS);

    share(type, isDesktop);
  };

  return (
    <div className={cn(css.socials, className)}>
      <img src={whatsup} alt="" data-id={SHARE_CONSTANTS.WHATSAPP} onClick={onShare} />
      <img src={sms} alt="" data-id={SHARE_CONSTANTS.EMAIL} onClick={onShare} />
      <img src={twitter} alt="" data-id={SHARE_CONSTANTS.TWITTER} onClick={onShare} />
      <img src={facebook} alt="" data-id={SHARE_CONSTANTS.FACEBOOK} onClick={onShare} />
    </div>
  );
};

export default Socials;
