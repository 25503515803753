import React, { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { useResponsive } from 'src/hooks/responsive';
import { ColorT } from '../sections-data';
import ArrowDown from './ArrowDown';
import Animation from './Animation';
import css from './Section.module.scss';

type Props = {
  index: number;
  className?: string;
  imageClassName?: string;
  heading: [string, string];
  title: string;
  children: JSX.Element;
  color: ColorT;
  imgUrl?: string;
  shortDescriptionMobile: string;
  longDescriptionMobile: string;
  isNeedToOpenLongDescription: boolean;
};

const textColor: Record<ColorT, string> = {
  red: '#FF6464',
  blue: '#234E70',
  yellow: '#FF7F11',
};

const Section: FC<Props> = (props) => {
  const {
    className = '',
    imageClassName = '',
    heading,
    title,
    children,
    color,
    imgUrl,
    shortDescriptionMobile,
    longDescriptionMobile,
    isNeedToOpenLongDescription,
  } = props;
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isLarge, setIsLarge] = useState<boolean | undefined>(undefined);
  const [isMobile] = useResponsive('MOBILE');
  const containerRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<Animation | null>(null);

  const onArrowClick = (): void => {
    setIsOpened((prevState) => !prevState);
  };

  useEffect(() => {
    if (descriptionRef.current) {
      const elementHeight = descriptionRef.current.getBoundingClientRect().height;

      setIsLarge(elementHeight > 210);
    }
  }, []);

  React.useEffect(() => {
    if (isLarge !== undefined) {
      const container = containerRef.current;

      if (container) {
        animationRef.current = new Animation();
        animationRef.current.init({ container, index: props.index });
        animationRef.current.start();
      }
    }
  }, [isLarge]);

  React.useEffect(() => {
    if (animationRef.current) {
      animationRef.current.onExpand(isOpened);
    }
  }, [isOpened]);

  return (
    <div className={cn(css.section, css[color], className)} ref={containerRef}>
      <div className={css.topSectionContent}>
        {!isMobile && imgUrl && (
          <div className={css.imgWrapper}>
            <img src={imgUrl} className={cn(imageClassName)} alt="" />
          </div>
        )}
      </div>
      <div className={css.bottomSectionContent}>
        <div className={cn(css.bg, props.index % 2 === 0 ? css.odd : css.even)}>
          <svg width="100%" height="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="" fill={textColor[color]} />
          </svg>
        </div>
        <div className={css.headingWrap}>
          <div className={css.heading}>
            <div className={css.headingSolid}>
              {heading[0].split('').map((char, index) => (
                <span key={char + index}>{char}</span>
              ))}
            </div>
            <div className={css.headingStroked}>
              {heading[1].split('').map((char, index) => (
                <span key={char + index}>{char}</span>
              ))}
            </div>
          </div>
        </div>
        {isMobile && imgUrl && (
          <div className={css.imgWrapper}>
            <img src={imgUrl} className={cn(imageClassName)} alt="" />
          </div>
        )}
        <div className={css.title}>{title}</div>
        <div className={cn(css.childrenWrapper)} ref={descriptionRef}>
          <div className={css.desktopDescription}>{children}</div>
          {!isOpened && (
            <div
              className={css.shortDescriptionMobile}
              dangerouslySetInnerHTML={{ __html: shortDescriptionMobile }}
            ></div>
          )}
          {isNeedToOpenLongDescription && isOpened && (
            <div
              className={css.longDescriptionMobile}
              dangerouslySetInnerHTML={{ __html: longDescriptionMobile }}
            ></div>
          )}
        </div>

        {isMobile && isNeedToOpenLongDescription && (
          <div className={css.popControl}>
            <div onClick={onArrowClick}>
              {isOpened ? <span>פחות</span> : <span>להמשך קריאה</span>}
              <ArrowDown color={color} className={cn(css.arrow, isOpened && css.open)} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Section;
