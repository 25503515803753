import React, { FC } from 'react';

import cn from 'classnames';

import { ColorT } from '../../sections-data';

type Props = {
  color: ColorT;
  className?: string;
};

const arrowColor: Record<ColorT, string> = {
  red: '#FF6464',
  blue: '#234E70',
  yellow: '#FF7F11',
};

const ArrowDown: FC<Props> = ({ color, className }) => {
  return (
    <svg
      className={cn(className)}
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L7 7L13 1" stroke={arrowColor[color]} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowDown;
