import React from 'react';
import ReactDOMClient, { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { App } from 'src/App';

const container = document.getElementById('root');
const Wrap = (
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);

if (container) {
  if (process.env.SSR) {
    ReactDOMClient.hydrateRoot(container, Wrap);
  } else {
    createRoot(container).render(Wrap);
  }
} else {
  throw new Error('No #root element in DOM');
}
