export const REDUX_STORAGE_KEY = '__REDUX_STORAGE';

export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const CONFIG = JSON.parse(process.env.CONFIG || '{}');

export const SHARE_CONSTANTS = {
  FACEBOOK: 'Facebook',
  WHATSAPP: 'Whatsapp',
  EMAIL: 'Mail',
  TWITTER: 'Twitter',
};

export const IS_DEV = process.env.NODE_ENV === 'development';
