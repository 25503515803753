import React from 'react';

import Socials from '../../../common/Socials';
import personImg from 'src/assets/images/topSection/topSectionPerson.png';
import logo from 'src/assets/images/topSection/logo.svg';
import animation from './animation';
import css from './TopSection.module.scss';

const TopSection = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const topText = 'עושים';
  const bottomText = 'שמות';

  React.useEffect(() => {
    const container = containerRef.current;

    if (container) {
      animation.init({ container });
      animation.start();
    }
  }, []);

  return (
    <div className={css.topSectionWrapper} ref={containerRef}>
      <div className={css.bg}>
        <svg width="100%" height="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="" fill="#FF6464" />
        </svg>
      </div>
      <div className={css.logo}>
        <img src={logo} alt="" />
      </div>
      <div className={css.lettersWrapper}>
        <div className={css.letters}>
          <p className={css.top}>
            {topText.split('').map((char, index) => (
              <span key={char + index}>{char}</span>
            ))}
          </p>
          <p className={css.bottom}>
            {bottomText.split('').map((char, index) => (
              <span key={char + index}>{char}</span>
            ))}
          </p>
        </div>
      </div>
      <div className={css.personImg}>
        <img src={personImg} alt="" />
      </div>
      <div className={css.description}>
        <span>
          לילך תמיד שנאה את הכחכוח, מאור חלם להיות ערן. עם עוד קצת רווח בשיניים אמיר דווקא יכול היה להיות יובל, לאהרוני
          - תקשיבו טוב - כמעט קראו בן גוריון, והשם של עמית (הבת) מזכיר את כל מה שהייתה מעדיפה לשכוח | 16 יוצרות ויוצרים
          מספרים על היחסים המורכבים שלהם עם השם הפרטי
        </span>
      </div>
      <div className={css.redLine} />
      <div className={css.mentionedBlock}>
        <p>
          עריכה: <span>הגר בוחבוט</span> | איורים: <span>דנה ברלב</span>
        </p>
      </div>
      <Socials className={css.socials} />
    </div>
  );
};

export default TopSection;
