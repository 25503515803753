import React from 'react';

import logo from 'src/assets/images/Footer/logo.svg';
import blackLogo from 'src/assets/images/topSection/logo.svg';

import css from './Footer.module.scss';

const Footer = () => {
  return (
    <div className={css.footer}>
      <div className={css.imagesWrapper}>
        <img src={blackLogo} alt="" />
        <img src={logo} alt="" />
      </div>
      <div className={css.textsWrapper}>
        <span>כתבו לנו</span>
        <div />
        <span>מדיניות פרטיות</span>
        <div />
        <span>תנאי שימוש</span>
      </div>
    </div>
  );
};

export default Footer;
