import React from 'react';

import { CAN_USE_DOM } from 'src/constants/other';
import { useResponsive } from 'src/hooks/responsive';
import { detectIE } from 'src/utils/detect-ie';

import AccessibilityIcon from 'src/assets/images/Accessibility/Acce.svg';

import css from './Accessibility.module.scss';

const Accessibility = () => {
  const [isDesktop] = useResponsive('DESKTOP');
  const accessablityBtn = () => {
    if (!CAN_USE_DOM) {
      return null;
    }

    return document.querySelector('.uai');
  };

  const onClick = (event: React.SyntheticEvent) => {
    const btn = accessablityBtn();
    if (btn) {
      const eventClick = new Event('click');
      btn.dispatchEvent(eventClick);
    }
  };

  const onMouseEnter = () => {
    let eventFocus;
    const btn = accessablityBtn();
    if (!CAN_USE_DOM) {
      return;
    }

    if (isDesktop) {
      if (detectIE() > 0) {
        eventFocus = document.createEvent('Event');
        eventFocus.initEvent('focus', false, true);
      } else {
        eventFocus = new Event('focus');
      }

      if (btn) {
        btn.dispatchEvent(eventFocus);
      }
    }
  };

  const onMouseLeave = () => {
    let eventBlur;
    const btn = accessablityBtn();

    if (!CAN_USE_DOM) {
      return;
    }

    if (detectIE() > 0) {
      eventBlur = document.createEvent('Event');
      eventBlur.initEvent('blur', false, true);
    } else {
      eventBlur = new Event('blur');
    }

    if (btn) {
      btn.dispatchEvent(eventBlur);
    }
  };

  return (
    <button className={css.accessablityBtn} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <img src={AccessibilityIcon} alt="" />
    </button>
  );
};

export default Accessibility;
