import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { createNoise2D } from 'simplex-noise';

import { isMatching } from 'src/hooks/responsive';
import { spline, SplinePoint } from 'src/utils/spline';
import { splinePoint, randomUV, vw, uv } from 'src/utils/animation';
import css from './TopSection.module.scss';

gsap.registerPlugin(ScrollTrigger);

interface Params {
  container: HTMLDivElement;
}

class Animation {
  private container?: HTMLDivElement;
  private bg?: HTMLDivElement | null;
  private svg?: SVGElement | null;
  private bgPath?: SVGPathElement | null;
  private personImgWrap?: HTMLDivElement | null;
  private personImg?: HTMLImageElement | null;
  private topText?: HTMLDivElement | null;
  private topTextChars?: NodeListOf<HTMLSpanElement> | null;
  private bottomText?: HTMLDivElement | null;
  private bottomTextChars?: NodeListOf<HTMLSpanElement> | null;

  private canvasW = 0;
  private canvasH = 0;
  private bgAnchorPoints: SplinePoint[] = [];

  private personImgDropDistance = 100;
  private personImgFloatOffset = 0.01;
  private personImgAnimationProress = 0;

  private noise = createNoise2D();
  private dropCharsTL: gsap.core.Timeline | null = null;

  private isMobile = isMatching('MOBILE');
  private frameCounter = 0;

  public init(params: Params) {
    this.container = params.container;
    this.bg = this.container?.querySelector(`.${css.bg}`);
    this.svg = this.container?.querySelector('svg');
    this.bgPath = this.svg?.querySelector('path');
    this.personImgWrap = this.container?.querySelector(`.${css.personImg}`);
    this.personImg = this.container?.querySelector(`.${css.personImg} img`);
    this.topText = this.container?.querySelector(`.${css.top}`);
    this.topTextChars = this.container?.querySelectorAll(`.${css.top} span`);
    this.bottomText = this.container?.querySelector(`.${css.bottom}`);
    this.bottomTextChars = this.container?.querySelectorAll(`.${css.bottom} span`);

    this.onResize();

    window.addEventListener('resize', this.onResize);
  }

  public start() {
    if (!this.bottomText || !this.personImgWrap) return;

    gsap.ticker.add(this.onRAF);

    this.dropCharsTL = gsap.timeline({
      scrollTrigger: {
        trigger: this.container,
        start: '10% top',
        end: '70% top',
        toggleActions: 'restart reset restart reverse', // enter, leave, enterBack, leaveBack
        onLeave: () => {
          this.container?.classList.remove(css.revealed);
        },
        onLeaveBack: () => {
          this.container?.classList.remove(css.revealed);
        },
      },
      onStart: () => {
        this.container?.classList.add(css.revealed);
      },
      onComplete: () => {
        this.destroy();
      },
    });

    this.topTextChars?.forEach((char, index) => {
      const vec = randomUV();
      const distanceY = this.isMobile ? 2000 : 620;

      this.dropCharsTL?.add(
        gsap.to(char, {
          duration: Math.max(1, Math.random() * 1.5),
          x: vw(20 * vec.x - index * 40),
          y: vw(distanceY + 20 * vec.y),
          scaleY: 0.75,
          rotate: vec.x * 50,
          ease: 'bounce.out',
        }),
        0,
      );
    });
    this.bottomTextChars?.forEach((char, index) => {
      const vec = randomUV();
      const distanceY = this.isMobile ? 1900 : 580;

      this.dropCharsTL?.add(
        gsap.to(char, {
          duration: Math.max(1, Math.random() * 1.5),
          x: vw(70 + (20 * Math.abs(vec.x) - index * 40)),
          y: vw(distanceY + 10 * vec.y),
          scaleY: 0.75,
          rotate: vec.x * 50,
          ease: 'bounce.out',
        }),
        0,
      );
    });

    this.dropCharsTL?.add(gsap.to(this, { duration: 1, personImgFloatOffset: 0 }), 0);
    this.dropCharsTL?.add(
      gsap.to(this.personImgWrap, {
        duration: 0.75,
        y: vw((this.isMobile ? 4 : 1) * this.personImgDropDistance),
        ease: 'bounce.out',
      }),
      0,
    );
  }

  public reset() {
    // reset
  }

  public destroy() {
    gsap.ticker.remove(this.onRAF);
  }

  private updateBgAnchorPoints() {
    if (this.isMobile === false) {
      const w = Math.max(this.canvasW, this.canvasH);
      const h = Math.max(this.canvasW, this.canvasH);
      const cr = w * 0.25;
      const points: SplinePoint[] = [];
      const bBox: SplinePoint[] = [
        splinePoint(-this.canvasW * 0.1, this.canvasH - h),
        splinePoint(this.canvasW * 1.1, this.canvasH - h),
        splinePoint(w, this.canvasH * 0.9),
        splinePoint(0, this.canvasH * 0.9),
      ];

      bBox.forEach((p, index) => {
        const np = bBox[index + 1] ?? bBox[0];
        const vec = uv(p, np);
        const x1 = p.x + vec.x * cr;
        const y1 = p.y + vec.y * cr;
        const x2 = np.x - vec.x * cr;
        const y2 = np.y - vec.y * cr;
        const p1 = splinePoint(x1, y1);
        const p2 = splinePoint(x2, y2);

        points.push(p1, p2);
      });

      this.bgAnchorPoints = points;
    } else {
      const w = this.canvasW;
      const h = Math.max(this.canvasW, this.canvasH) + w * 0.15;
      const r = Math.max(Math.min(Math.abs(1 - h / w), 0.3), 0.1);
      const topY = this.canvasH * 0.4 - h / 2;

      this.bgAnchorPoints = [
        splinePoint(w * 0.1, topY + h * 0.0),
        splinePoint(w * (1 + r), topY),
        splinePoint(w * (1 + r), topY + h),
        splinePoint(w * -r, topY + h),
      ];
    }
  }

  private drawBg() {
    const noiseStep = 0.001;
    const floatingDistance = this.canvasW * 0.025;

    this.bgAnchorPoints.forEach((p) => {
      const noiseX = this.noise(p.noiseOffsetX, p.noiseOffsetX);
      const noiseY = this.noise(p.noiseOffsetY, p.noiseOffsetY);

      p.x = p.originX + floatingDistance * noiseX;
      p.y = p.originY + floatingDistance * noiseY;

      p.noiseOffsetX += noiseStep;
      p.noiseOffsetY += noiseStep;
    });

    this.bgPath?.setAttribute('d', spline(this.bgAnchorPoints, 1.2, true));
  }

  private animatePerson() {
    if (!this.personImg || this.personImgFloatOffset === 0) return;

    const rad = Math.PI / 180;
    const floatingDistance = this.canvasW * this.personImgFloatOffset * (this.isMobile ? 4 : 1);
    const posY = -this.personImgDropDistance + Math.sin(this.personImgAnimationProress * rad) * floatingDistance;

    gsap.set(this.personImg, { translateY: `${posY}px` });
    this.personImgAnimationProress += 3;
  }

  private onResize = () => {
    this.isMobile = isMatching('MOBILE');
    this.canvasW = this.bg?.offsetWidth || 0;
    this.canvasH = this.bg?.offsetHeight || 0;

    this.svg?.setAttribute('viewBox', `0 0 ${this.canvasW} ${this.canvasH}`);

    this.updateBgAnchorPoints();
    this.drawBg();
  };

  private onRAF = () => {
    this.frameCounter++;

    if (this.frameCounter % 2 === 0) {
      this.drawBg();
      this.animatePerson();
    }
  };
}

const animation = new Animation();
export default animation;
