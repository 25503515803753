import gsap from 'gsap';
import { createNoise2D } from 'simplex-noise';

import { isMatching } from 'src/hooks/responsive';
import { spline, SplinePoint } from 'src/utils/spline';
import { splinePoint, randomUV, vw } from 'src/utils/animation';
import css from './Section.module.scss';

interface Params {
  container: HTMLDivElement;
  index: number;
}

class Animation {
  private container?: HTMLDivElement;
  private bg?: HTMLDivElement | null;
  private svg?: SVGElement | null;
  private bgPath?: SVGPathElement | null;
  private headingTextPt1?: HTMLDivElement | null;
  private headingTextCharsPt1?: NodeListOf<HTMLSpanElement> | null;
  private headingTextCharsPt2?: NodeListOf<HTMLSpanElement> | null;
  private headingImage?: HTMLImageElement | null;

  private canvasW = 0;
  private canvasH = 0;
  private bgAnchorPoints: SplinePoint[] = [];

  private noiseStepPls = 0;
  private floatingDistancePls = 0;

  private noise = createNoise2D();
  private revealTL: gsap.core.Timeline | null = null;

  private isMobile = isMatching('MOBILE');
  private isSvgCloned = false;
  private frameCounter = 0;
  private isOdd = false;

  public init(params: Params) {
    this.container = params.container;
    this.bg = this.container?.querySelector(`.${css.bg}`);
    this.svg = this.container?.querySelector('svg');
    this.bgPath = this.svg?.querySelector('path');
    this.headingTextPt1 = this.container?.querySelector(`.${css.headingSolid}`);
    this.headingTextCharsPt1 = this.container?.querySelectorAll(`.${css.headingSolid} span`);
    this.headingTextCharsPt2 = this.container?.querySelectorAll(`.${css.headingStroked} span`);
    this.headingImage = this.container?.querySelector(`img`);

    this.isOdd = params.index % 2 === 0;

    this.onResize();

    window.addEventListener('resize', this.onResize);
  }

  public start() {
    if (!this.svg) return;

    this.revealTL = gsap.timeline({
      scrollTrigger: {
        trigger: this.container,
        start: this.isMobile === false ? 'top center' : 'top 85%',
        end: '140% top',
        toggleActions: 'restart reset restart reverse', // enter, leave, enterBack, leaveBack
        onLeave: () => {
          this.container?.classList.remove(css.revealed);
        },
        onLeaveBack: () => {
          this.container?.classList.remove(css.revealed);
        },
      },
      onStart: () => {
        if (this.isMobile && this.svg) {
          gsap.set(this.svg, { height: this.svg.clientHeight });
        }
        this.container?.classList.add(css.revealed);
        gsap.ticker.add(this.onRAF);
        this.revealTL?.timeScale(1);
      },
      onComplete: () => {
        this.destroy();
        this.revealTL?.timeScale(3);
      },
    });

    this.revealTL.fromTo(
      this.svg,
      { scale: 0, y: vw(-100) },
      {
        scale: 1,
        y: 0,
        duration: 1,
        ease: 'back.out(1)',
        onStart: () => {
          gsap.fromTo(this, { noiseStepPls: 0.01 }, { noiseStepPls: 0, duration: 5, ease: 'power2.out' });
          gsap.fromTo(
            this,
            { floatingDistancePls: 0.1 },
            { floatingDistancePls: 0, duration: 5, ease: 'elastic.out(1, 0.2)' },
          );
        },
      },
    );

    this.headingTextCharsPt1?.forEach((char) => {
      const vec = randomUV();
      this.revealTL?.fromTo(
        char,
        { x: vw(vec.x * 50 + 50), y: vw(vec.y * 100 - 200), rotate: Math.abs(vec.x) * 90, opacity: 1 },
        { x: 0, y: 0, rotate: 0, duration: Math.max(1, Math.random() * 1.5), opacity: 1, ease: 'bounce.out' },
        0,
      );
    });

    const headingTextPt1Rect = this.headingTextPt1?.offsetWidth || 0;
    this.headingTextCharsPt2?.forEach((char) => {
      const vec = randomUV();
      this.revealTL?.fromTo(
        char,
        { x: vw(vec.x * 50 + headingTextPt1Rect), y: vw(vec.y * 100 - 200), rotate: Math.abs(vec.x) * 90, opacity: 1 },
        { x: 0, y: 0, rotate: 0, duration: Math.max(1, Math.random() * 1.5), opacity: 1, ease: 'bounce.out' },
        0,
      );
    });
  }

  public onExpand(isExpanded: boolean) {
    if (isExpanded && this.isSvgCloned === false) {
      const svgClone = this.svg?.cloneNode(true) as SVGElement;

      svgClone.classList.add(css.cloned);
      this.bg?.appendChild(svgClone);
      this.isSvgCloned = true;
    }
  }

  public reset() {
    // reset
  }

  public destroy() {
    gsap.ticker.remove(this.onRAF);
  }

  private updateBgAnchorPoints() {
    if (this.isMobile === false) {
      const w = this.canvasW;
      const h = Math.max(this.canvasW * 0.85, this.canvasH);
      const topY = this.canvasH / 2 - h / 2;

      this.bgAnchorPoints = [
        splinePoint(w * 0.2, topY),
        splinePoint(w * 0.95, topY),
        splinePoint(w * 0.95, topY + h),
        splinePoint(0, topY + h * 0.9),
      ];
    } else {
      const w = this.canvasW;
      const h = Math.max(this.canvasW, this.canvasH) + w * 0.15;
      const r = Math.max(Math.min(Math.abs(1 - h / w), 0.3), 0.1);
      const topY = this.canvasH * 0.4 - h / 2;

      this.bgAnchorPoints = [
        splinePoint(w * 0.1, topY + h * 0.0),
        splinePoint(w * (1 + r), topY),
        splinePoint(w * (1 + r), topY + h),
        splinePoint(w * -r, topY + h),
      ];
    }
  }

  private drawBg() {
    const noiseStep = 0.0015 + this.noiseStepPls;
    const floatingDistance = this.canvasW * (0.015 + this.floatingDistancePls);

    this.bgAnchorPoints.forEach((p) => {
      const noiseX = this.noise(p.noiseOffsetX, p.noiseOffsetX);
      const noiseY = this.noise(p.noiseOffsetY, p.noiseOffsetY);

      p.x = p.originX + floatingDistance * noiseX;
      p.y = p.originY + floatingDistance * noiseY;

      p.noiseOffsetX += noiseStep;
      p.noiseOffsetY += noiseStep;
    });

    this.bgPath?.setAttribute('d', spline(this.bgAnchorPoints, 1.5, true));
  }

  private onResize = () => {
    this.isMobile = isMatching('MOBILE');
    this.canvasW = this.bg?.offsetWidth || 0;
    this.canvasH = this.bg?.offsetHeight || 0;

    this.svg?.setAttribute('viewBox', `0 0 ${this.canvasW} ${this.canvasH}`);

    this.updateBgAnchorPoints();
    this.drawBg();
  };

  private onRAF = () => {
    this.frameCounter++;

    if (this.frameCounter % 2 === 0) {
      this.drawBg();
    }
  };
}

export default Animation;
